@import 'src/styles/variables';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: $padding;

	pointer-events: none;
}

.banner {
	display: flex;
	align-items: center;
	padding: 10px 20px 10px 15px;

	color: white;
	font-size: 1rem;
	font-weight: 600;

	border-radius: $radius;
	background-color: $red;
	user-select: none;
	pointer-events: auto;
	z-index: 2000;
}

.picto {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	fill: white;
}
