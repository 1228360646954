@import 'src/styles/variables';

.error {
	padding: $padding * 2 $padding;
	color: $grey;
	font-size: 21px;
	text-align: center;
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	padding: $padding;

	@include media('<laptop') {
		padding: 0 $padding/2 $padding;
	}
}

.title {
	flex: 0 0 100%;
	margin: 30px 0 #{30px - $padding / 2};
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

.single {
	flex: 0 0 calc(50% - #{$padding * 2});
	margin: $padding;

	@include media('<laptop') {
		flex: 1 1 calc(50% - #{$padding});
		margin: $padding / 2;
	}
}
