@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: $padding;
	border-radius: $radius;
	background: white;
	box-shadow: $shadow;
}

.skeleton {
	@include pulse;

	&::before {
		color: transparent;
		background: $ultraLightGrey;
	}
}

.img {
	@include media('>=tablet') {
		flex: 1 0 40%;
		max-height: 300px;
		margin: $padding;
	}

	&.skeleton {
		background-color: $ultraLightGrey;
	}
}

.imgComponent {
	height: 100%;

	img {
		object-position: top;
	}
}

.text {
	@include media('>=tablet') {
		flex: 1 1 50%;
		padding: $padding;
	}
}

.titre {
	margin: $padding 0 10px;
	font-size: 16px;
	line-height: 1.2;
	text-align: left;

	@include media('>=tablet') {
		margin-top: 0;
		font-size: 18px;
		font-weight: 600;
	}

	@include media('>=desktop') {
		font-size: 30px;
	}

	&.skeleton {
		&::before {
			content: 'w w w w w w';
		}
	}
}

.contenu {
	max-width: 520px;
	font-size: 13px;
	line-height: 1.3;
	text-align: left;

	@include media('>=tablet') {
		font-size: 1rem;
	}

	@include media('>=desktop') {
		font-size: 20px;
	}

	&.skeleton {
		&::before {
			content: 'w w w w w w w w w w w w w w w w w w w w w w w';
		}
	}
}

.button {
	display: inline-block;
	white-space: nowrap;
	margin-top: $padding;

	@include media('>=laptop', '<desktop') {
		padding-left: 15px;
		padding-right: 15px;
	}

	// &.skeleton {
	// 	color: transparent;
	// 	background-color: $ultraLightGrey;
	// }
}
