@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	padding: 0 $padding;
	font-size: 13px;
	transition-duration: $duration;
	transition-property: color, background-color;

	&:hover {
		background-color: $ultraLightGrey;
	}

	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		margin-left: 10px;
		border: 6px solid transparent;
		border-bottom: none;
		border-top: 8px solid currentColor;
	}
}

.selected {
	color: $primary;
}
