@import 'src/styles/variables';

.wrapper {
	padding: $padding;
	text-align: center;
}

.svg {
	width: 15px;
	height: 15px;
	margin-right: 5px;
	vertical-align: -2px;

	path {
		fill: currentColor;
	}
}
