@import 'src/styles/variables';

.gradient {
	position: relative;
	height: $padding * 2;
	margin-bottom: $padding * -2;
	margin-right: 16px; // pour laisser la scrollbar non masquée (16px pour Edge)
	overflow: hidden;
	z-index: 1;

	@include media('<laptop') {
		height: $padding;
		margin-bottom: $padding * -1;
	}

	&.reversed {
		margin-top: $padding * -2;
		margin-bottom: 0;
		transform: rotate(180deg);
		transform-origin: 50% 51%; // nécessaire pour corriger micropixel de décalage

		@include media('<laptop') {
			margin-top: $padding * -1;
		}
	}

	&::before {
		content: '';
		display: block;
		box-shadow: 0 0 $padding $padding $background;

		@include media('<laptop') {
			box-shadow: 0 0 $padding/2 $padding/2 $background;
		}
	}
}
