@import 'src/styles/variables';

.count {
	display: inline-block;
	min-width: 20px;
	padding: 4px 6px;

	color: white;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	text-align: center;

	background-color: $primary;
	border-radius: 50px;

	transition: {
		property: color, background-color;
		duration: $duration;
	}
}

.inverted {
	color: $primary;
	background-color: white;
}
