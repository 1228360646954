@import 'src/styles/variables';

.main {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	overflow: hidden;
}

.scrollable {
	flex: 1;
	overflow-y: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	@include media('<laptop') {
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
