@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 10px 0;
}

.datePicker {
	margin-left: -10px;
}
