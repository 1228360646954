@import 'src/styles/variables';

.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 38px;
	height: 38px;

	transition-duration: $duration;
	transition-property: fill, stroke, opacity, background-color; // on laisse opacity si ça doit être animé

	circle {
		fill: $primary;
	}

	path {
		fill: $lightGrey;
		transition: $duration fill;
	}

	&.hasValue {
		path {
			fill: $primary;
		}
	}

	@include mouse {
		&:hover {
			path {
				fill: $grey;
			}

			&.hasValue {
				path {
					fill: darken($primary, 10);
				}
			}
		}
	}
}

.picto {
	width: 32px;
	stroke: inherit;
	margin-top: 3px;
}
