@import 'src/styles/variables';

.wrapper {
	position: relative;
	margin: 0 $padding * 2 $padding * 2;
	border-radius: 0 0 $radius $radius;
	background-color: white;
	overflow: hidden;

	@include media('<tablet') {
		margin: $padding;
		margin-top: 0;
	}
}
