@import 'src/styles/variables';

.done {
	padding: $padding * 2;
	text-align: center;
}

.svg {
	display: block;
	width: 40px;
	margin: 0 auto $padding;
	stroke: $green;
}

.message {
	margin-bottom: 30px;
	color: $darkGrey;
}
