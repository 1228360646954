@import 'src/styles/variables';

$size: 35px;

.wrapper {
	font-size: 13px;
	user-select: none;
}

.count {
	margin-bottom: 10px;
}

.nav {
	display: flex;
	height: $size;
}

.link {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: $size;
	margin-right: 1px;
	color: $primary;
	background-color: white;

	&:hover:not(.active) {
		background-color: $ultraLightGrey;
	}
}

.prev,
.next {
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 4px;
		right: 0;
		bottom: 0;
		width: 10px;
		height: 10px;
		margin: auto;
		border: 2px solid currentColor;
		border-right: none;
		border-bottom: none;
		transform: rotate(-45deg);
	}
}

.next {
	margin-right: 10px;

	&::before {
		left: -4px;
		transform: rotate(135deg);
	}
}

.disabled {
	pointer-events: none;

	&::before {
		border-color: $line;
	}
}

.active {
	color: white;
	background-color: $primary;
	pointer-events: none;
}
