@import 'src/styles/variables';

.wrapper {
	padding: 5px 10px;
	cursor: pointer;
	user-select: none;
	transition: $duration color;

	&:hover {
		color: $primary;
	}
}

.radio {
	display: inline-block;
	position: relative;
	vertical-align: -5px;
	margin-right: 10px;
	width: 20px;
	height: 20px;
	border: 1px solid $primary;
	border-radius: 50%;

	&::after {
		content: '';
		position: absolute;
		top: 3px;
		left: 3px;
		right: 3px;
		bottom: 3px;
		border-radius: 50%;
		background-color: $primary;
		transition-duration: $duration;
		transition-property: opacity, transform;

		.wrapper:not(.checked) & {
			opacity: 0;
			transform: scale(0);
		}
	}
}
