@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 50px;
	height: 50px;

	fill: rgba($color: $primary, $alpha: 0.3);
	transition-duration: $duration;
	transition-property: fill, stroke, opacity, background-color; // on laisse opacity si ça doit être animé

	@include mouse {
		&:hover {
			fill: $red;
		}
	}

	&.checked {
		fill: $red;
	}
}

.picto {
	height: 14px;
	fill: inherit;

	.animation & {
		animation: pop $duration;
	}
}

@keyframes pop {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
}
