@import 'src/styles/variables';

.link {
	padding: $padding / 2 0;
	color: $grey;
	font-size: 13px;
	line-height: 1;
	text-align: center;
	transition: $duration color;

	&:hover {
		color: $darkGrey;
	}
}
