@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	padding: $padding / 2;
	border-radius: $radius;
	transition: $duration background-color;
	cursor: pointer;

	&.loading {
		background-color: $ultraLightGrey;
		cursor: default;
	}
}

.picto {
	display: flex;
	justify-content: center;
	align-items: center;

	flex: 0 0 28px;
	height: 28px;
	margin-right: 15px;
	stroke: $text;

	border-radius: 50%;
	background-color: $ultraLightGrey;
	transition-duration: $duration;
	transition-property: stroke, background-color;

	.wrapper:not(.loading) &:hover {
		stroke: $primary;
		background-color: $lightGrey;
	}

	svg {
		width: 14px;
		height: 14px;
	}
}

.input {
	width: 100%;
	text-overflow: ellipsis;
	border: 1px solid transparent;
	transition: $duration opacity;

	&:focus {
		border-bottom-color: $line;
	}

	.loading & {
		opacity: 0.5;
	}
}
