@import 'src/styles/variables';

.omniSearchOnMobile {
	width: 100vw;
	height: map-get($headerHeight, big);

	@include media('<tablet') {
		height: map-get($headerHeight, small);
	}
}
