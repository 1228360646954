@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	color: $text;
	line-height: 1;

	cursor: pointer;
	user-select: none;
}

.input {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
}

.checkbox {
	display: block;
	position: relative;
	width: 18px;
	height: 18px;
	border: 1px solid currentColor;
	border-radius: 2px;
	transition: $duration color;

	.wrapper:not(:hover):not(.checked):not(.halfChecked) & {
		color: $grey;
	}

	&::after {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;

		border-radius: inherit;
		background-color: currentColor;
		transition-duration: $duration;
		transition-property: opacity, transform;

		opacity: 0;
		transform: scale(0.5);

		.halfChecked & {
			opacity: 1;
			transform: scaleY(0.2);
		}

		.checked & {
			opacity: 1;
			transform: none;
		}
	}
}

.text {
	margin-left: 20px;
}
