@import 'src/styles/variables';

.button {
	display: inline-block;
	position: relative;
	padding: 8px 25px;

	color: white;
	font-size: 1rem;
	line-height: 1;

	border-radius: 100px;
	background-color: $primary;
	cursor: pointer;
	user-select: none;

	transition-duration: $duration;
	transition-property: color, border-color, background-color, box-shadow;

	@include mouse {
		&:hover:not(.disabled):not(:disabled) {
			&:not(.inverted) {
				background-color: darken($primary, 10);
			}

			&.inverted {
				box-shadow: $shadow;
			}

			&.outline {
				color: white;
				background-color: $primary;
			}
		}
	}

	&.inverted {
		color: $primary;
		background-color: white;
	}

	&.outline {
		color: $primary;
		border: 1px solid $primary;
		background-color: transparent;
	}

	&:disabled,
	&.disabled {
		background-color: $grey;
		cursor: not-allowed;

		&.inverted {
			color: $grey;
			background-color: white;
		}

		&.outline {
			color: $grey;
			border-color: $grey;
			background-color: transparent;
		}
	}
}

.submit {
	align-self: center;
	margin: $padding 0;
}

.text {
	transition: $duration opacity;

	.loading & {
		opacity: 0;
	}
}

.loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 16px;
	height: 16px;
	margin: auto;
	color: white;
	transition: $duration opacity;

	.button:not(.loading) & {
		opacity: 0;
	}
}
