@import 'src/styles/variables';

.title {
	margin: $padding $padding 10px;
	font-size: 20px;
	text-align: center;
}

.text {
	max-width: 350px;
	margin: 0 $padding $padding;
}
