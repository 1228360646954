@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	padding: 0 $padding / 2;
}

.separator {
	width: 1px;
	height: 30px;
	margin: 0 $padding / 2;
	border: none;
	background-color: $line;
}

.btnRecurrence {
	display: inline-flex;
	align-items: center;
	align-self: center;
	vertical-align: middle;
	padding-left: 15px;
	text-align: center;
}

.pictoHorloge {
	flex: 0 0 21px;
	height: 21px;
	margin-right: 10px;
	fill: currentColor;
}
