@import 'src/styles/variables';

$dur: 300;
:export {
	duration: $dur;
}

.fullScreen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: map-get($headerHeight, big) $padding $padding;
	text-align: center;
	z-index: 2;

	@include media('<tablet') {
		padding-top: map-get($headerHeight, small);
	}
}

.cache {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: white;
	z-index: -1;
	transform-origin: 0 0;
	transition-duration: $dur + 0ms;
	transition-property: opacity, transform;

	.enter:not(.enterActive) &,
	.exit & {
		opacity: 0;
		transform: scaleY(0);
	}
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 2px;
		height: 20px;
		margin: auto;
		background-color: $grey;
		transform: rotate(45deg);
		transition: $dur - 100ms transform;
	}

	&::after {
		transform: rotate(-45deg);
	}

	.enter:not(.enterActive) &,
	.exit & {
		&::before {
			transform: rotate(135deg) scaleY(0);
		}

		&::after {
			transform: rotate(45deg) scaleY(0);
		}
	}
}

.link {
	display: block;
	padding: 10px $padding;
	user-select: none;
	white-space: nowrap;
	cursor: pointer;
	transition-duration: $duration;
	transition-property: color, background-color;

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}

	@include media('<laptop') {
		padding: 15px 0;
		font-size: 20px;
		line-height: 1;
		border-bottom: 1px solid $line;
		transition-duration: $dur - 100ms;
		transition-property: opacity, transform;

		.enter:not(.enterActive) &,
		.exit & {
			opacity: 0;
			transform: translateY(-20px);
		}
	}

	&.current {
		color: $primary;
		cursor: default;

		@include media('>laptop') {
			background-color: $lightGrey;
		}
	}
}
