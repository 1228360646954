@import 'src/styles/variables';

$transitionDuration: 200;
:export {
	duration: $transitionDuration;
}

.cache {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	transition: $transitionDuration * 1ms opacity;
	pointer-events: none;

	path {
		fill: rgba($black, 0.7);
		pointer-events: auto;
		cursor: not-allowed;
	}

	.enter:not(.enterActive) &,
	.exit & {
		opacity: 0;
	}
}

.contentWrapper {
	position: fixed;
	font-size: 1rem;
	z-index: 1001;
	max-width: 500px;

	@include media('<laptop') {
		left: $padding !important;
		right: $padding !important;
		margin: auto;
		transform: none !important;

		&.noMarginOnMobile {
			left: 0 !important;
			right: 0 !important;
		}

		&.bottom {
			transform: translateY(-100%) !important;
		}
	}
}

.hider {
	overflow: hidden;
	transition: $transitionDuration * 1ms transform;

	@include edge {
		transition: none;
	}

	.enter:not(.enterActive) &,
	.exit & {
		transform: translateY(-100%);
	}

	.enter:not(.enterActive) .bottom &,
	.exit .bottom & {
		transform: translateY(100%);
	}
}

.content {
	background-color: white;
	transition: $transitionDuration * 1ms transform;

	@include edge {
		transition: none;
	}

	.enter:not(.enterActive) &,
	.exit & {
		transform: translateY(100%);
	}

	.enter:not(.enterActive) .bottom &,
	.exit .bottom & {
		transform: translateY(-100%);
	}
}
