@import 'src/styles/variables';

.title {
	margin-bottom: 15px;
	font-size: 25px;
	line-height: 1;
	text-align: center;

	@include media('<=mobile') {
		font-size: 20px;
	}
}
