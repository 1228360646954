@import 'src/styles/variables';

.title {
	margin: $padding $padding 10px;
	font-size: 20px;
	text-align: center;
}

.textarea {
	height: 150px;
	width: 350px;
	max-width: calc(100vw - #{$padding * 4});
	margin: 0 $padding $padding;
}

.validerWrapper {
	text-align: center;
}

.valider {
	margin-bottom: $padding;
}
