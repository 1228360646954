@import 'src/styles/variables';

$lineHeight: 13;
$transitionDuration: 200;
:export {
	duration: $transitionDuration;
	lineHeight: $lineHeight;
	padding: $padding;
}

.error {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $red;
	font-size: $lineHeight + 0px;
	line-height: 1.3;
	text-align: center;
	transition-property: height, opacity;
	transition-duration: $transitionDuration + 0ms;
}

.enter:not(.enterActive),
.exitActive {
	height: 0 !important;
	opacity: 0;
}
