@import 'src/styles/variables';

.wrapper {
	width: 100%;
	border-top: 1px solid $line;
	background-color: white;
}

.lastTr {
	height: 50px;
}

.skeleton {
	display: block;
	margin: (50px - 20px) / 2 0;
	height: 20px;
	min-width: 20px;
	background-color: $lightGrey;
	@include pulse;
}

.zeroResult {
	position: relative;
	padding-top: $padding * 2;
	color: $grey;
	font-size: 16px;
	text-align: center;
}

.error {
	color: $red;
}
