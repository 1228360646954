@import 'src/styles/variables';

$dur: 300;
:export {
	duration: $dur;
}

.header {
	width: 500px;
	padding: 10px;
	color: $primary;
	font-size: 20px;
	text-align: center;
	border-bottom: 1px solid $line;

	@include media('<tablet') {
		width: auto;
	}
}

.error {
	padding: $padding;
	color: $red;
	font-size: 13px;
	text-align: center;
}

.noAlerts {
	padding: $padding;
	color: $darkGrey;
	text-align: center;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	padding: $padding;

	@include media('<tablet') {
		justify-content: center;
	}
}

.navButtons {
	@include media('<tablet') {
		flex: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
}

.deleteAll {
	margin-left: auto;

	@include media('<tablet') {
		margin: 0;
	}
}
