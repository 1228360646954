@import 'src/styles/variables';

.wrapper {
	display: block;
	position: relative;
	color: $grey;
	transition: $duration color;
	cursor: pointer;

	&:hover,
	&.highlight {
		color: $primary;
	}
}

.img {
	position: relative;

	.cta {
		color: white;
		background-color: rgba(black, 0.5);

		.wrapper:not(:hover):not(.highlight) & {
			opacity: 0;
		}
	}
}

.dropzone {
	position: relative;
	padding-bottom: 56.25%;
	border: 3px dashed currentColor;
}

.cta {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: $duration opacity;
}

.icon {
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
	stroke: currentColor;
}

.input {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 1px;
	height: 1px;
	margin: auto;
	opacity: 0;
}
