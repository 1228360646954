@import 'variables';

#buorg {
	width: 500px;
	border-bottom: none;
	background: lighten($red, 40);
	box-shadow: none;
	animation: none;
	@media (max-width: 600px) {
		width: auto;
		top: 20px;
		left: 20px;
		right: 20px;
	}
}

.buorg-pad {
	padding: 30px !important;
	@media (max-width: 600px) {
		padding: 20px !important;
	}
}

#buorgig,
#buorgul,
#buorgpermanent {
	box-shadow: none !important;
	margin-top: 20px !important;
	line-height: 1.7;
	@media (max-width: 600px) {
		margin-top: 10px !important;
	}
}

#buorgig {
	background-color: $grey !important;
}

.buorg-fadeout {
	transition: 0s 3s !important;
}
