@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	flex: 0 0 360px;
	height: 100%;
	border-top: 2px solid $background;
	z-index: 1; // pour passer par-dessus le Gradient des résultats de recherche

	@include media('<1200px') {
		flex-basis: 310px;
	}

	@include media('<laptop') {
		display: block;
		border: none;
		background-color: $background;
	}
}

.scrollable {
	flex: 1;
	padding: $padding;
	padding-bottom: 0;
	background: white;
	overflow-y: auto;
	overflow-y: overlay; // webkit only -> superpose la scrollbar au contenu

	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 3px;
	}
	&::-webkit-scrollbar-track {
		background-color: white;
	}

	@include media('<laptop') {
		overflow: hidden;
	}
}

// Header
.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: $padding;
}

.title {
	font-size: 20px;
}

.subtitle {
	color: $primary;
}

.delete {
	flex: 0 0 60px;
	height: 60px;
}

// Call to action to commande en cours
.cta {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $padding;

	@include media('>=laptop') {
		height: map-get($footerHeight, big);
		background: $primary;
	}
}
