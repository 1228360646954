@import 'src/styles/variables';

.small {
	font-size: 12px;
}

.big {
	font-size: 18px;

	.small & {
		font-size: 14px;
	}
}

.na {
	color: $grey;
}
