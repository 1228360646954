@import 'src/styles/variables';

$focusColor: $primary;

.label {
	display: flex;
	flex-direction: column-reverse;
	margin: 15px 0;
}

.input {
	height: 40px;
	padding-left: 15px;
	color: $text;
	border: 1px solid $line;
	background-color: $ultraLightGrey;
	transition: 0.2s border-color;
	appearance: none;

	.label:hover & {
		border-color: $grey;
	}

	.label &:focus {
		border-color: $focusColor !important;
	}

	&:not(textarea):not([value='']):invalid {
		border-color: $red;
	}
}

.textarea {
	padding: 15px;
	height: 250px;
	resize: none;
}

.text {
	display: block;
	padding-bottom: 10px;
	color: $darkGrey;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;

	.label:hover & {
		color: $primary;
	}

	.input:focus + & {
		color: $focusColor !important;
	}

	.input:not(textarea):not([value='']):invalid + & {
		color: $red;
	}
}
