@import 'src/styles/variables';

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: $padding;
	background-color: $primary;
}

.invalidOrder {
	color: #fff;
}

.exclamation {
	display: inline-block;
	width: 21px;
	margin: 0 10px;
	box-sizing: content-box;

	color: $red;
	font-weight: 600;
	line-height: 21px;
	text-align: center;

	border: 1px solid currentColor;
	border-radius: 50%;
}

.infoLivraison {
	max-width: 500px;
	margin: 0 auto 15px;
	color: #fff;
	text-align: center;

	strong {
		font-weight: 600;
	}
}
