@import 'src/styles/variables';

.form {
	text-align: center;
}

.title {
	margin-bottom: 20px;
	font-size: 20px;
	line-height: 1.2;
}

.subtitle {
	margin-bottom: 20px;
	color: $darkGrey;
}

.error {
	color: $red;
	font-size: 12px;
}

.wrapperSearch {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	text-align: left;
}

.search {
	width: 100%;
	padding: 0 10px;
	border-bottom: 1px solid $line;
}

.loupe {
	pointer-events: none;
}

.list {
	height: 150px;
	overflow-y: auto;
	box-sizing: content-box;
	border: 1px solid $line;
}

.client {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px $padding;
	line-height: 1.3;
	text-align: left;
	cursor: pointer;
	transition-duration: $duration;
	transition-property: color, background-color;

	&:hover {
		background-color: $ultraLightGrey;
	}

	&.selected {
		color: white;
		background-color: $primary;
	}
}

.groupe {
	display: block;
	color: $primary;
	text-transform: uppercase;
	font-size: 12px;
	transition: $duration color;

	.selected & {
		color: white;
	}
}

.noClients {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: $darkGrey;
}

.logout {
	align-self: center;
	padding: 0 10px;
	color: $grey;
	font-size: 13px;
	transition: $duration color;

	&:hover,
	&:focus {
		color: $text;
	}
}
