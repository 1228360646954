@import 'src/styles/variables';

.wrapper {
	padding: 0 $padding;
	border-bottom: 1px solid $line;
	transition: $duration box-shadow;

	&:hover {
		box-shadow: $shadow;
	}
}

.clickable {
	cursor: pointer;
}

.checkbox {
	margin: -30px;
	padding: 30px;
	height: 100%;
}
