@import 'src/styles/variables';

.wrapper {
	max-width: 400px;
	padding: $padding;
	text-align: center;
}

.title {
	margin-bottom: 10px;
	font-size: 20px;
}

.text {
	margin-bottom: 20px;
}

.loader {
	margin: 60px auto;
}

.error {
	color: $red;
}
