@import 'src/styles/variables';

.figure {
	display: block;
	user-select: none;
}

.img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.imgNotFound {
	display: block;
	fill: $grey;
	width: 100%;
	height: 100%;
}
