@import 'src/styles/variables';

.wrapper {
	max-width: 600px;
	margin: auto;
	padding: $padding * 2 $padding 150px;

	h1 {
		margin-bottom: $padding;
		color: $primary;
		font-size: 40px;
		text-align: center;

		counter-reset: section;

		@include media('<=mobile') {
			font-size: 25px;
		}
	}

	h2 {
		margin-top: 30px;
		color: $primary;
		font-size: 26px;
		line-height: 1.3;

		counter-reset: sub-section;

		@include media('<=mobile') {
			font-size: 20px;
		}

		&::before {
			counter-increment: section;
			content: counter(section) '. ';
		}
	}

	h3 {
		margin-top: 20px;
		color: $primary;
		font-size: 20px;

		@include media('<=mobile') {
			font-size: 18px;
		}

		&::before {
			counter-increment: sub-section;
			content: counter(section) '.' counter(sub-section) '. ';
		}
	}

	p {
		margin: 15px 0;
	}

	a {
		color: $primary;
		text-decoration: underline;
	}
}
