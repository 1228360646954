@import 'src/styles/variables';

.wrapper {
	display: flex;
	justify-content: flex-end;
	height: $filtresHeight;
	margin: $padding * 2;
	margin-bottom: 0;
	border-radius: $radius;
	background: white;
	overflow: hidden;

	@include media('<laptop') {
		margin: $padding;
		margin-bottom: 0;
	}
}

.skeleton {
	align-self: center;
	width: 100px;
	height: 15px;
	margin-right: $padding;
	background-color: $lightGrey;
	@include pulse;
}

.error {
	color: $red;
	font-size: 13px;
}
