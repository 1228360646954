@import 'src/styles/variables';

.numero {
	font-weight: 600;
}

.nom, .com {
	font-weight: 600;
	line-height: 1.3;
	text-transform: uppercase;
}

.produits {
	color: $grey;
	font-size: 13px;
	line-height: 1.5;
	max-width: 400px;
}
