@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-wrap: wrap;
	padding: 0 $padding;

	@include media('<laptop') {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
