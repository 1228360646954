@import 'src/styles/variables';

.btn {
	display: inline-flex;
	align-items: center;
	padding: 0 $padding;
	line-height: 1;
	transition: $duration background-color;

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}
}

.hidden {
	pointer-events: none;
	opacity: 0.1;
}

.arrow {
	width: 17px;
	height: 14px;
	stroke: $black;
}

.withText {
	margin-right: $padding;
}
