@import 'src/styles/variables';

.btn {
	width: 80px;
	text-align: center;
	transition: $duration background-color;

	@include media('<tablet') {
		width: auto;
	}

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}

	&.isAlertesOpen {
		background-color: $primary;
	}
}

.svg {
	margin-top: 6px;
	width: 41px;
	fill: $primary;
	transition: $duration fill;

	@include ie {
		vertical-align: -20px;
	}

	.isAlertesOpen & {
		fill: white;
	}
}
