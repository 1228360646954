@import 'src/styles/variables';

.header {
	display: flex;
	justify-content: space-between;
	height: map-get($headerHeight, big);
	background-color: white;

	@include media('<tablet') {
		height: map-get($headerHeight, small);
	}
}

.logo {
	@include media('<tablet') {
		width: 170px;
	}
}

.disconnect {
	display: flex;
	align-items: center;
	justify-content: center;
	width: map-get($headerHeight, big);
	stroke: $grey;
	cursor: pointer;
	transition-duration: $duration;
	transition-property: stroke, background-color;

	@include media('<tablet') {
		width: map-get($headerHeight, small);
	}

	@include mouse {
		&:hover {
			stroke: $red;
			background-color: $ultraLightGrey;
		}
	}

	svg {
		width: 35px;
		height: 35px;
		stroke: inherit;
	}
}

.content {
	padding: $padding * 2;

	@include media('<tablet') {
		padding-left: $padding;
		padding-right: $padding;
	}
}
