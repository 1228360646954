@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	color: white;
	background-color: $primary;
	border-radius: 50%;
	transition-duration: $duration;
	transition-property: color, background-color;

	@include mouse {
		&:hover {
			color: $primary;
			background-color: $secondary;
		}
	}
}

.picto {
	margin-bottom: 2px;
	height: 20px;
	fill: currentColor;
}
