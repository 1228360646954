@import 'src/styles/variables';

$color1: $secondary;
$color2: $primary;

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 $padding;
	transition: $duration background-color;

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}
}

.bonjour {
	padding-right: 15px;

	@include media('<laptop') {
		display: none;
	}

	span {
		font-weight: 600;
	}
}

.initial {
	display: block;
	width: $btnMenuCircleSize;
	height: $btnMenuCircleSize;
	color: $primary;
	font-size: 22px;
	line-height: 40px;
	text-align: center;
	background-color: $color1;
	border-radius: 100px;
	transition: {
		property: color, background-color;
		duration: $duration;
	}

	@include mouse {
		.btn:hover & {
			background-color: mix($color1, $color2, 70%);
		}
	}

	.btn.isMenuCompteOpen & {
		color: white;
		background-color: $color2;
	}
}
