@import 'src/styles/variables';

.list {
	display: flex;
	flex-wrap: wrap;
	margin-top: $padding * -1;
	margin-bottom: $padding * -1;
	padding-top: $padding;
	padding-left: $padding;

	@include media('>=laptop') {
		padding: 0 $padding;
	}
}

.item {
	flex: 1 1 380px;
	padding-right: $padding;
	padding-bottom: $padding;

	@include media('>=laptop') {
		padding: $padding;
	}
}

.fake {
	padding-top: 0;
	padding-bottom: 0;
}

.warning {
	padding: $padding * 2;
	color: $red;
}

.skeleton {
	height: 150px;
	background: $ultraLightGrey;
	@include pulse;
}

.pagination {
	margin: $padding * 2;
}
