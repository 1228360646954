@import 'src/styles/variables';

.label {
	display: inline-block;
	margin-right: 4px;
	padding: 0 4px;
	height: 18px;
	color: white;
	line-height: 18px;
	font-size: 12px;
	font-weight: 600;
	border-radius: 4px;
	background: $green;
	user-select: none;
}
