@import 'src/styles/variables';

$dur: 300;
:export {
	duration: $dur;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	right: $padding;
	bottom: $padding;
	max-width: 320px;
	padding-left: 20px;
	padding-right: 10px;
	box-shadow: $shadow;
	z-index: 1;
	transition-duration: $dur * 1ms;
	transition-property: opacity, transform;

	@include media('<laptop') {
		bottom: map-get($footerHeight, big) + $padding;
	}

	@include media('<tablet') {
		left: $padding;
		bottom: map-get($footerHeight, small) + $padding;
		margin: auto;
	}

	&.enter:not(.enterActive),
	&.exit {
		opacity: 0;
		transform: translateY(100%) translateY($padding);
	}

	&.enter {
		transition-delay: $dur * 1ms + 1s;
	}
}

.svg {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	// stroke: #fff;
	// stroke-width: 3px;
}

.delete {
	display: block;
	position: relative;
	width: 30px;
	height: 30px;
	margin-left: 10px;
	transition: $duration opacity;

	&:not(:hover) {
		opacity: 0.5;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 2px;
		height: 20px;
		background-color: white;
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.loader {
	margin-left: 10px;
	color: white;
}
