@import 'src/styles/variables';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

	> * {
		margin-left: 5px;
		margin-right: 5px;
	}
}

.oeil {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 38px;
	height: 38px;
	border-radius: 50%;
	background-color: $primary;
	fill: white;

	svg {
		width: 25px;
	}
}
