@import 'src/styles/variables';

.loader {
	width: 30px;
	height: 30px;
	color: $grey;
	border: 1px solid transparent;
	border-top-color: currentColor;
	border-bottom-color: currentColor;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
