@import 'src/styles/variables';

.mobileTitle {
	display: flex;
	align-items: center;

	.photo {
		margin-right: 15px;
	}
}

.photo {
	width: 80px;
	height: 60px;
}

.nom {
	font-weight: 600;
	text-transform: uppercase;
}

.error {
	color: $red;
}

.big {
	font-size: 18px;
}
