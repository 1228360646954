@import 'src/styles/variables';

.wrapper {
	position: relative;
	margin: 0 $padding * 2 $padding * 2;
	background-color: white;

	@include media('<tablet') {
		margin: $padding;
		margin-top: 0;
	}
}

.footer {
	display: flex;
	justify-content: center;
	padding: $padding;
	background-color: $primary;

	@include media('<tablet') {
		padding: $padding 0;
	}
}
