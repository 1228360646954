@import 'src/styles/variables';

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 45px;
	color: white;
	font-size: 20px;
	background-color: $primary;

	@include media('>=laptop') {
		min-width: 350px;
	}
}

.svg {
	width: 25px;
	fill: white;
	margin-right: 10px;
}

.main {
	padding: $padding;
}

.radio {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.block {
	margin-top: $padding;
	padding: 25px $padding;
	text-align: center;
	box-shadow: $shadow;
}

.blockTitle {
	margin-bottom: 15px;
	color: $primary;
	line-height: 1;
}

.dayOfMonth {
	vertical-align: 0;
	width: 3ch;
	margin: 0 5px;
	text-align: center;
	border: 1px solid $primary;
}

.weekDays {
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
}

.buttonWrapper {
	text-align: center;
	padding: 30px 0 10px;
}
