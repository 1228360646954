@import 'src/styles/variables';

.wrapper {
	margin: 0 $padding;
	padding: $padding * 2;
	text-align: center;
	border-radius: $radius;
	background: $primary;
	box-shadow: $shadow;
}

.text {
	margin-bottom: 30px;
	color: white;
	font-size: 16px;
}

.button {
	font-size: 21px;
}
