@import 'src/styles/variables';

.line {
	display: flex;
	align-items: center;
	padding: 0 $padding * 2;
	height: 35px;

	@include media('>=laptop', '<desktop') {
		padding-top: $padding;
		padding-bottom: $padding;
	}
}

.picto {
	flex: 0 0 24px;
	width: 24px;
	height: 24px;
	margin-right: 10px;
	background: $lightGrey;
	@include pulse;
}

.text {
	height: 13px;
	background: $lightGrey;
	@include pulse;
}
