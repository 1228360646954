@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 38px;

	color: $darkGrey;
	font-size: 18px;
	line-height: 1;

	cursor: pointer;
	user-select: none;
	transition: $duration color;

	&:hover {
		color: $primary;
	}

	&.checked {
		color: $primary;
		font-weight: 600;
	}
}

.checkbox {
	display: block;
	height: 14px;
	width: 14px;
	margin-top: 10px;
	border: 1px solid $primary;
	transform: rotate(45deg);

	&::after {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		background-color: $primary;
		transition-duration: $duration;
		transition-property: opacity, transform;

		.wrapper:not(.checked) & {
			opacity: 0;
			transform: scale(0);
		}
	}
}
