@import 'src/styles/variables';

.wrapper {
	vertical-align: middle;
	padding: $padding $padding / 2;

	&:first-child {
		padding-left: $padding;
	}

	&:last-child {
		padding-right: $padding;
	}
}

.centered {
	text-align: center;
	width: 1px;
}

.noPointer {
	cursor: default;
}
