@import 'src/styles/variables';

.header {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding * 2;
}

.btnBack {
	position: absolute;
	left: 0;
	height: 50px;
}

.title {
	flex: 1;
	color: $primary;
	font-size: 24px;
	font-weight: 600;
	line-height: 1;
	text-align: center;
}

.form {
	background-color: white;

	@include media('>tablet') {
		flex-direction: row;
	}
}

.upload {
	flex: 1;

	img {
		width: 100%;
	}
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: $padding * 2;

	@include media('<=tablet') {
		margin-top: $padding * 2;
		margin-left: 0;
	}
}

.inputTitre {
	margin-top: 0;
}

.btnSubmit {
	@include media('>tablet') {
		align-self: flex-start;
	}
}
