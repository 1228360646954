@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 50px;
	height: 50px;

	fill: $red;
	transition-duration: $duration;
	transition-property: fill, opacity, background-color; // on laisse opacity si ça doit être animé

	@include mouse {
		// &:hover {
		// 	background-color: $ultraLightGrey;
		// }

		&:not(:hover) {
			fill: $grey;
		}
	}
}

.picto {
	height: 20px;
}
