@import 'src/styles/variables';

.link {
	display: flex;
	align-items: center;
	width: 200px + $padding * 2;
	padding: 0 $padding;
}

.logo {
	width: 100%;
}
