@import 'src/styles/variables';

.wrapper {
	color: $grey;
	font-size: 12px;
	line-height: 1.3;
	text-align: center;
}

.selected {
	color: $darkGrey;
	font-weight: 600;
}

.day {
	margin: 0 2px;
}
