@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 100%;
	height: 100%;
}

.middle {
	display: flex;
	position: relative;
	flex: 1;
	overflow: hidden;
}

.sideMenuWrapper {
	flex: 1;
	height: 100%;
}
