@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: $padding;
	min-height: 100vh;
}

.logo {
	margin: auto 0 20px;
	@include media('>=tablet') {
		margin-bottom: 40px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	margin-bottom: auto;
	width: 100%;
	max-width: 400px;
	background: white;
}
