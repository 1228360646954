@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	align-self: center;
	margin: 10px 0;
}

.hidden {
	opacity: 0.5;
}

.loader {
	width: 15px;
	height: 15px;
	margin-left: -10px;
	margin-right: 10px;
	color: #fff;
}
