@import 'src/styles/variables';

.footer {
	display: flex;
	position: relative;
	flex-shrink: 0;
	height: map-get($footerHeight, small);
	padding-bottom: calc(env(safe-area-inset-bottom) - 15px);
	box-sizing: content-box;
	background: white;

	@include media('>=tablet') {
		height: map-get($footerHeight, big);
	}

	> a,
	> button {
		flex: 33%;
	}
}
