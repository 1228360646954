@import 'src/styles/variables';

.wrapper {
	padding: 15px 0;
}

.tri {
	display: inline-block;
	position: relative;
	color: $darkGrey;
	font-size: 12px;
	white-space: nowrap;

	&:first-child {
		margin-right: 10px;
	}

	&:nth-child(2) {
		margin-right: 30px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 5px;
		bottom: 3px;
		width: 6px;
		height: 6px;
		margin: auto;
		border: 1px solid $grey;
		border-top: none;
		border-left: none;
		transform: rotate(45deg);
	}
}

.select {
	vertical-align: baseline;
	padding: 5px;
	color: $text;
	font-size: 15px;
	font-family: arial;
	line-height: 1;
	border: none;
	cursor: pointer;
}
