@import 'src/styles/variables';

.wrapper {
	display: flex;
	border-bottom: 1px solid $line;
	transition-duration: $duration;
	transition-property: opacity, box-shadow;

	&:hover {
		box-shadow: 0 0 10px rgba(162, 160, 231, 0.25);
	}
}

.nonLu {
	font-weight: 600;
}

.deleting {
	opacity: 0.5;
	pointer-events: none;
}

.link {
	flex: 1;
	padding: 15px $padding;
}

.date {
	color: $grey;
	font-size: 12px;
}

button.delete {
	flex: 0 0 60px;
	height: auto;
	border: none;

	@include ie {
		height: 80px;
	}

	@include mouse {
		.wrapper:not(:hover) & {
			opacity: 0;
		}
	}
}
