@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: $padding;
	height: 100%;
}

.title {
	color: $grey;
	font-size: 100px;
	line-height: 1;
}

.text {
	margin: 20px 0 30px;
}
