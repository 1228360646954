@import 'src/styles/variables';

.button {
	display: flex;
	justify-content: center;
	align-items: center;

	transition: $duration background-color;
}

.active {
	background-color: $ultraLightGrey;
	pointer-events: none;
}

.svg {
	stroke: $primary;
}
