@import 'src/styles/variables';

.button {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: $btnMenuCircleSize;
	height: $btnMenuCircleSize;
	color: $primary;
	transition: $duration color;

	&:disabled {
		pointer-events: none;
	}
}

.svg {
	position: relative;
	width: 29px;
	height: 29px;

	@include media('<laptop') {
		width: 22px;
		height: 22px;
	}
}
