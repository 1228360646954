@import 'src/styles/variables';

.wrapper {
	margin: $padding * 2;
	padding: $padding * 2;
	background-color: white;

	@include media('<tablet') {
		padding: $padding;
	}

	@include media('<laptop') {
		margin: $padding;
	}

	p {
		margin-top: 10px;
	}

	a {
		color: $primary;
		&:hover {
			color: darken($primary, 10);
		}
	}
}

.title {
	margin-bottom: $padding;
	color: $primary;
	font-size: 25px;
	line-height: 1;

	@include media('<tablet') {
		font-size: 20px;
		text-align: center;
	}
}

.links {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include media('<=mobile') {
		justify-content: center;
		text-align: center;
	}
}

.websiteLink {
	margin-right: 10px;

	@include media('<=mobile') {
		flex: 100%;
		margin-bottom: 10px;
	}
}

.social {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}
