@import 'src/styles/variables';

.noResult,
.error {
	padding: 20px;
	color: $grey;
	font-size: 16px;
	text-align: center;
}

.error {
	color: $red;
}

.skeleton {
	align-self: center;
	height: 14px;
	flex: 1;
	background-color: $lightGrey;
	@include pulse;

	&.title {
		height: 24px;
	}
}
