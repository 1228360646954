@import 'src/styles/variables';

.form {
	display: flex;
	flex-direction: column;
	padding: $padding * 2;

	@include media('<mobile') {
		padding-left: $padding;
		padding-right: $padding;
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}
