@import 'node_modules/include-media/dist/include-media';

// Hover
@mixin mouse {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
	@include ie {
		@content;
	}
}

// Pulse
@mixin pulse {
	:global {
		animation: pulse 1s infinite;
	}
}

// Edge only
@mixin edge {
	@supports (-ms-ime-align: auto) {
		@content;
	}
}

// IE only
@mixin ie {
	@media all and (-ms-high-contrast: none) {
		@content;
	}
}
